.reshare-post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  backdrop-filter: blur(45.7534px);
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.reshare-post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer;
}

.reshare-post-card-wrapper .card-badge.hidden {
  background: #7e7285;
}

.reshare-post-card-wrapper .created-at {
  font-size: var(--font-size-small);
}

.reshare-post-card-wrapper .author {
  font-size: var(--font-size);
  font-weight: 500;
  cursor: pointer;
}
