.wallet-recharge-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.wallet-recharge-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.wallet-recharge-wrapper .form-control {
  border: none;
  border-bottom: 2px solid #d5d5d5;
  border-radius: 0;
  padding: 0;
  box-shadow: none !important;
}

/* Stripe card */
.CardNumberField-input-wrapper {
  width: 300px !important;
}

.CardNumberField-input-wrapper .InputContainer .InputElement {
  width: 200px !important;
}

.CardBrandIcon-container.is-hidden {
  width: 50px !important;
}
