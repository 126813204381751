.post-card-wrapper {
  flex-grow: 1;
  margin: 0 0.5rem !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 7px !important;
  font-size: var(--font-size);
  backdrop-filter: blur(45.7534px);
}

.post-card-wrapper {
  background: linear-gradient(
    180deg,
    rgba(194, 95, 255, 0.6) 68.68%,
    #ffb47e 111.32%
  );
}

.post-card-wrapper.hidden {
  background: linear-gradient(180deg, #9a9a9a 62.98%, #959595 102.49%);
}

.post-card-wrapper.add-filter {
  filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));
}

.post-card-wrapper.reshare-box {
  margin: 0 !important;
  border-top: 1px solid var(--color-white-600) !important;
  border-top-right-radius: 2px !important;
  border-top-left-radius: 2px !important;
}

.post-card-wrapper .card-body {
  padding: var(--spacing-16) 20px var(--spacing-1);
  border-radius: 0 0 21px 21px;
  background: var(--color-white);
}

.post-card-wrapper .card-badge {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
  cursor: pointer;
}

.post-card-wrapper .card-badge.hidden {
  background-color: #959595;
}

.post-card-wrapper .card-amount .currency-text {
  position: relative;
  left: -0.35rem;
}

.post-card-wrapper .currency-icon {
  width: 12px;
  height: 12px;
  position: relative;
  left: 0.12rem;
}

.post-card-wrapper .created-at {
  float: right;
  font-size: var(--font-size-small);
}

.post-card-wrapper .card-text {
  font-size: var(--font-size);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}

.post-card-wrapper .card-follow {
  float: right;
}

.post-card-wrapper .card-body .carousel-effect .prev {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: left;
  cursor: pointer;
}

.post-card-wrapper .card-body .carousel-effect .next {
  font-size: var(--font-size-small);
  background-color: var(--color-gray-100);
  padding: 0.3rem;
  float: right;
  cursor: pointer;
}

.post-card-wrapper .card-footer {
  border: 0px !important;
  /* padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-24); */
}

.post-card-wrapper .card-footer .author {
  font-size: var(--font-size);
  font-weight: 500;
}

.post-card-wrapper .card-footer img {
  width: 45px;
  height: 45px;
  position: relative;
  top: -7px;
}

.post-card-wrapper .card-footer .edit-options {
  float: right;
}

.scaleInImgFollow {
  animation: scaleIn 0.75s 2;
}

.scaleInImgUnfollow {
  animation: scaleIn 0.75s 1;
}

@-webkit-keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }
  50% {
    transform: scale(1.7);
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
  }
  100% {
    transform: scale(1);
    filter: none;
  }
}
@keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }
  50% {
    transform: scale(1.7);
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
  }
  100% {
    transform: scale(1);
    filter: none;
  }
}

.hidden-post .blur-text {
  filter: blur(6px);
  padding: var(--spacing-4) !important;
}

.hidden-post .locked-post {
  color: var(--color-violet-400);
  text-align: center;
  margin-top: var(--spacing-12);
}

.hidden-post .locked-post-text {
}

.hidden-post .locked-post-text .currency-text {
  position: relative;
  left: -3px;
}

.hidden-post .locked-post-text .currency-icon {
  width: var(--spacing-12);
  height: var(--spacing-12);
  position: relative;
  left: 2px;
}

.hidden-post .locked-post-icon {
  width: var(--spacing-48);
  height: var(--spacing-48);
}

.icon-share {
  width: 18px !important;
  height: 18px !important;
}

.icon-more {
  width: 20px !important;
  height: 20px !important;
  object-fit: contain !important;
}

.icon-heart {
  width: 21px !important;
  height: 21px !important;
  object-fit: contain !important;
}

/* Share post */
.share-details {
  text-align: center;
  font-size: 12px;
  margin: 2rem 0 0;
}

.share-details .copy-link-success {
  font-size: var(--font-size-small);
  background-color: var(--color-violet-400);
  color: var(--color-white);
  text-align: center;
  margin: 1rem 0;
}

.share-details .copy-link-icon {
  width: 36px;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.88);
}

.share-details .copy-link-icon .fa-link {
  font-size: var(--font-size-bigger);
  padding-top: var(--spacing-8);
}

@media (min-width: 576px) {
  .audio-player-wrapper .audio-btn .btn-play {
    width: 15px !important;
  }

  .audio-player-wrapper .audio-btn .btn-pause {
    width: 10px !important;
  }

  .post-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}
